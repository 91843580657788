import { Typography } from "~~/components/ds/Typography";

import PropTypes from "prop-types";
import React from "react";

export default function Generic({ classes }) {
  return (
    <>
      <Typography component="h1" className={classes.pageHeader}>
        Oops, something went wrong
      </Typography>
      <Typography component="p" className={classes.subtext}>
        Please try refreshing your browser.
      </Typography>
    </>
  );
}

Generic.propTypes = {
  classes: PropTypes.shape(),
};

Generic.defaultProps = {
  classes: {},
};
