import { Typography } from "~~/components/ds/Typography";

import React from "react";

import PropTypes from "prop-types";

const LostPasswordless = ({ classes }) => (
  <>
    <Typography component="h1" className={classes.pageHeader}>
      Followed an invalid passwordless link
    </Typography>

    <Typography component="p" className={classes.subtext}>
      Please go to your event page and request a new passwordless link
    </Typography>
  </>
);

LostPasswordless.propTypes = {
  classes: PropTypes.shape(),
};

LostPasswordless.defaultProps = {
  classes: {},
};

export default LostPasswordless;
