/**
 * @fileoverview Wrapper component to compose things like theme, css baseline, and flash messages at an application
 * level.
 */
import PropTypes from "prop-types";
import React from "react";
import "~~/services/applicationService";

import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { AppUIStateProvider } from "~~/containers/AppUIStateContainer";
import store from "~~/redux/store";

import FlashMessages from "~~/components/common/FlashMessages";
import { THEMES } from "~~/constants";
import { getTheme } from "~~/theme";

export default function App(props) {
  const { theme, children, title, flashMessages, darkMode } = props;

  return (
    <Provider store={store}>
      <AppUIStateProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Helmet>
            <title>{title}</title>
            <meta name="description" content="Helmet application" />
          </Helmet>

          <div className={darkMode ? "dark" : ""}>
            <FlashMessages messages={flashMessages} />
            {children}
          </div>
        </ThemeProvider>
      </AppUIStateProvider>
    </Provider>
  );
}

App.propTypes = {
  theme: PropTypes.shape(),
  title: PropTypes.string,
  children: PropTypes.shape().isRequired,
  flashMessages: PropTypes.arrayOf(PropTypes.shape()),
};

App.defaultProps = {
  theme: getTheme(THEMES.ONE_DARK),
  title: "Welcome",
  flashMessages: [],
};
