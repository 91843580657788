import React from "react";
import ReactDOM from "react-dom";
import FullScreenLoader from "~~/components/common/FullScreenLoader";
import ErrorScreen from "~~/components/errors/Show";
import axios from "~~/utils/authenticatedAxios";

// Remove trailing slash from pathname
const sanitizedPathname = window.location.pathname.replace(/\/+$/, "");

// Client-side "routes" belong here! KEEP THIS ALPHABETIZED OR YOU WILL BE FIRED
// path matching regex => fn returning promise of page component (i.e. lazy component)
/** @type {(routeExp: Regex, lazyPageComponent: () => Component, propResourceFn: Function)[]} */
const pages = [
  [
    /^\/compatibility-check$/,
    () => import("~~/components/compatibility_check/Index"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/errors\/lost_passwordless$/,
    () => import("~~/components/errors/Show"),
    async () => ({
      data: { data: { errorPage: "lostPasswordless" } },
    }),
  ],
  [
    /^\/event_series\/[^/]+$/,
    () => import("~~/components/event_series/ShowRoot"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/event_series\/[^/]+\/overview$/,
    () => import("~~/components/event_series/DashboardOverview"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+$/,
    () => import("~~/components/events/Show"),
    () => axios.get(`${sanitizedPathname}.json?page_json=1`),
  ],
  [
    /^\/events\/[^/]+\/access_settings$/,
    () => import("~~/components/access_settings/index"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/analytics\/attendance$/,
    () => import("~~/components/events/analytics/Attendance"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/analytics\/attendance_detailed$/,
    () => import("~~/components/events/analytics/AttendanceDetailed"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/analytics\/breakout$/,
    () => import("~~/components/events/analytics/Breakout"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/analytics\/chat$/,
    () => import("~~/components/events/analytics/Chat"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/analytics\/lounge$/,
    () => import("~~/components/events/analytics/Lounge"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/analytics\/on_demand$/,
    () => import("~~/components/events/analytics/OnDemand"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/analytics\/one_on_one$/,
    () => import("~~/components/events/analytics/OneOnOne"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/analytics\/polling$/,
    () => import("~~/components/events/analytics/Polling"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/analytics\/qa$/,
    () => import("~~/components/events/analytics/Qa"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/analytics\/stage_cta$/,
    () => import("~~/components/events/analytics/StageCta"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/analytics\/report_card$/,
    () => import("~~/components/events/analytics/ReportCard"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/custom_registration_fields_spec$/,
    () => import("~~/components/custom_registration_fields_specs/Show"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/custom_reg_page$/,
    () => import("~~/components/custom_reg_pages/Overview"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/edit$/,
    () => import("~~/components/events/Edit"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/emails$/,
    () => import("~~/components/events/emails/Index"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/event_registrations$/,
    () => import("~~/components/event_registrations/Index"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
  [
    /^\/events\/[^/]+\/event_assets$/,
    () => import("~~/components/event_assets/Index"),
    () => axios.get(`${sanitizedPathname}?page_json=1`),
  ],
  [
    /^\/events\/[^/]+\/integrations$/,
    () => import("~~/components/tray_webhook/Index"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/photo_booth_templates$/,
    () => import("~~/components/photo_booth_templates/Index"),
    () => axios.get(`${sanitizedPathname}?page_json=1`),
  ],
  [
    /^\/events\/[^/]+\/overview$/,
    () => import("~~/components/events/Overview"),
    () => axios.get(`${sanitizedPathname}.json?page_json=1`),
  ],
  [
    /^\/events\/[^/]+\/stage_recording_files$/,
    () => import("~~/components/stage_recording_files/Index"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/sponsors$/,
    () => import("~~/components/sponsors/Index"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
  [
    /^\/events\/[^/]+\/stages\/[^/]+$/,
    () => import("~~/components/stages/ShowRoot"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
  [
    /^\/events\/[^/]+\/stages\/[^/]+\/stage_details$/,
    () => import("~~/components/stage_details/Show"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/events\/[^/]+\/streams$/,
    () => import("~~/components/streams/Index"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/forgot_password$/,
    () => import("~~/components/users/passwords/New"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/mobile_stream_agora_launcher$/,
    () => import("~~/components/stages/MobileStreamAgoraLauncher"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
  [
    /^\/mobile_stream_agora_publisher$/,
    () => import("~~/components/stages/MobileStreamAgoraPublisher"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
  [
    /^\/mobile_stream_agora_stage_player$/,
    () => import("~~/components/stages/MobileStreamAgoraStagePlayer"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
  [
    /^\/organizations\/[^/]+\/events$/,
    () => import("~~/components/events/Index"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
  [
    /^\/organizations\/[^/]+\/sso_configs\/new$/,
    () => import("~~/components/sso_configs/New"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/organizations\/[^/]+\/integrations$/,
    () => import("~~/components/integrations/Index"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/organizations\/[^/]+\/analytics$/,
    () => import("~~/components/analytics/Index"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/organizations\/[^/]+\/manage_users$/,
    () => import("~~/components/manage_users/Index"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/simple_event_creation$/,
    () => import("~~/components/events/SimpleCreation"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
  [
    /^\/stage_mobile_stream$/,
    () => import("~~/components/stages/ShowRecording"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
  [
    /^\/stage_recordings$/,
    () => import("~~/components/stages/ShowRecording"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
  [
    /^\/users\/sign_in$/,
    () => import("~~/components/users/sessions/New"),
    () => axios.get(`${sanitizedPathname}.json`),
  ],
  [
    /^\/users\/invitation\/accept$/,
    () => import("~~/components/users/invitations/Accept"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
  [
    /^\/users\/invitation\/accept_success$/,
    () => import("~~/components/users/invitations/AcceptSuccess"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
  [
    /^\/users\/invitation\/brand_assets$/,
    () => import("~~/components/users/invitations/BrandAssets"),
    () => axios.get(`${sanitizedPathname}.json${window.location.search}`),
  ],
];

const page = pages.find(([routeExp]) => routeExp.test(sanitizedPathname));

// Helper code which allows us to access the promise synchronously in our component
function wrapPromise(promise) {
  let status = "pending";
  let response;

  const suspender = promise.then(
    (res) => {
      status = "success";
      response = res;
    },
    (err) => {
      status = "error";
      response = err;
    }
  );

  const handler = {
    pending: () => {
      throw suspender;
    },
    error: () => {
      throw response;
    },
    default: () => response,
  };

  const read = () => {
    const result = handler[status] ? handler[status]() : handler.default();
    return result;
  };

  return {
    read,
  };
}

// Error page implementation; fetches context data & renders error
function ErrorScreenWithContextData(errorScreenProps) {
  const [contextData, setContextData] = React.useState({});

  React.useEffect(() => {
    axios
      .get(`/errors/context_data`)
      .then(({ data }) => setContextData(data))
      .catch((err) => console.error(err));
  }, []);

  const props = { ...contextData, ...errorScreenProps };
  return <ErrorScreen {...props} />;
}

if (page) {
  const [, componentPromise, propsFn] = page;

  // Begin loading props before beginning render, as recommended:
  // https://17.reactjs.org/docs/concurrent-mode-suspense.html
  const propsResource = wrapPromise(propsFn());
  const Component = React.lazy(componentPromise);

  // this needs more testing, seems to swallow some errors
  // eslint-disable-next-line no-unused-vars
  class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error) {
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      console.error("Error caught by boundary", error, errorInfo);
      Sentry?.withScope((scope) => {
        scope.setTag("exception-type", "react-top-error-boundary");
        scope.setExtra("errorInfo", JSON.stringify(errorInfo));
        Sentry?.captureException(error);
      });
    }

    render() {
      if (this.state.hasError) {
        return <ErrorScreenWithContextData data={{ errorPage: "generic" }} />;
      }

      return this.props.children;
    }
  }

  // eslint-disable-next-line
  function PageComponent() {
    const { data } = propsResource.read();
    return <Component {...data} />;
  }

  // eslint-disable-next-line
  function PageLoader() {
    return (
      <ErrorBoundary>
        <React.Suspense fallback={<FullScreenLoader />}>
          <PageComponent />
        </React.Suspense>
      </ErrorBoundary>
    );
  }

  ReactDOM.render(<PageLoader />, document.getElementById("root"));
} else {
  ReactDOM.render(
    <ErrorScreenWithContextData params={{ code: 404 }} />,
    document.getElementById("root")
  );
}
