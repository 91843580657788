/* eslint-disable import/prefer-default-export */
import STAGE_QUESTION_STATUS from "~~/constants/stageQuestionStatus";
import {
  ADD_STAGE_QUESTION,
  ARCHIVE_STAGE_QUESTIONS,
  DELETE_STAGE_QUESTION,
  DOWNVOTE_STAGE_QUESTION,
  SET_STAGE_QUESTIONS,
  UPDATE_STAGE_QUESTION,
  UPDATE_STAGE_QUESTION_STATUS,
  UPVOTE_STAGE_QUESTION,
} from "~~/redux/actionList";

export const setStageQuestions = (stageQuestions) => ({
  type: SET_STAGE_QUESTIONS,
  stageQuestions,
});

export const addStageQuestion = (stageQuestion) => ({
  type: ADD_STAGE_QUESTION,
  stageQuestion,
});

export const updateStageQuestion = (stageQuestion) => ({
  type: UPDATE_STAGE_QUESTION,
  stageQuestion,
});

export const deleteStageQuestion = (stageQuestion) => ({
  type: DELETE_STAGE_QUESTION,
  stageQuestion,
});

export const upvoteStageQuestion = (stageQuestion) => ({
  type: UPVOTE_STAGE_QUESTION,
  stageQuestion,
});

export const downvoteStageQuestion = (stageQuestion) => ({
  type: DOWNVOTE_STAGE_QUESTION,
  stageQuestion,
});

export const archiveStageQuestions = () => ({
  type: ARCHIVE_STAGE_QUESTIONS,
});

// Status updates
export const approveStageQuestion = (questionId) => ({
  type: UPDATE_STAGE_QUESTION_STATUS,
  questionId,
  status: STAGE_QUESTION_STATUS.ASKED,
});

export const unapproveStageQuestion = (questionId) => ({
  type: UPDATE_STAGE_QUESTION_STATUS,
  questionId,
  status: STAGE_QUESTION_STATUS.AWAITING_APPROVAL,
});

export const rejectStageQuestion = (questionId) => ({
  type: UPDATE_STAGE_QUESTION_STATUS,
  questionId,
  status: STAGE_QUESTION_STATUS.REJECTED,
});

export const unrejectStageQuestion = (questionId) => ({
  type: UPDATE_STAGE_QUESTION_STATUS,
  questionId,
  status: STAGE_QUESTION_STATUS.AWAITING_APPROVAL,
});
