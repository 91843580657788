import {
  ALLOW_PERMISSION_CAN_ASK_QUESTIONS,
  DENY_PERMISSION_CAN_ASK_QUESTIONS,
} from "~~/redux/actionList";

export const allowPermissionCanAskQuestions = () => ({
  type: ALLOW_PERMISSION_CAN_ASK_QUESTIONS,
});

export const denyPermissionCanAskQuestions = () => ({
  type: DENY_PERMISSION_CAN_ASK_QUESTIONS,
});
