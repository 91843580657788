import {
  ADD_VOTED_ON_STAGE_QUESTION_ID,
  REMOVE_VOTED_ON_STAGE_QUESTION_ID,
  SET_VOTED_ON_STAGE_QUESTION_IDS,
} from "~~/redux/actionList";

export const setVotedOnStageQuestionIds = (votedOnStageQuestionIds) => ({
  type: SET_VOTED_ON_STAGE_QUESTION_IDS,
  votedOnStageQuestionIds,
});

export const addVotedOnStageQuestionId = (votedOnStageQuestionId) => ({
  type: ADD_VOTED_ON_STAGE_QUESTION_ID,
  votedOnStageQuestionId,
});

export const removeVotedOnStageQuestionId = (votedOnStageQuestionId) => ({
  type: REMOVE_VOTED_ON_STAGE_QUESTION_ID,
  votedOnStageQuestionId,
});
