import * as actions from "~~/redux/actions/permissions";
import store from "~~/redux/store";

export function permitAskQuestions() {
  store.dispatch(actions.allowPermissionCanAskQuestions());
}

export function denyAskQuestions() {
  store.dispatch(actions.denyPermissionCanAskQuestions());
}
