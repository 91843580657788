import { Typography } from "~~/components/ds/Typography";

import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import DuoIcon from "~~/components/common/icons/DuoIcon";
import HeadphonesIcon from "~~/components/common/icons/HeadphonesIcon";

const Forbidden = ({ classes }) => (
  <>
    <Typography component="h1" className={classes.pageHeader}>
      Hey there, it looks like you don’t have access to this page
    </Typography>

    <Grid container spacing={5} style={{ marginTop: 24 }}>
      <Grid item xs={6}>
        <DuoIcon />
        <Typography variant="subtitle2" style={{ marginTop: 21 }}>
          I’m an attendee
        </Typography>
        <Typography component="p" className={classes.subtext}>
          Make sure you click the direct link you received in your invite
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <HeadphonesIcon />
        <Typography variant="subtitle2" style={{ marginTop: 13 }}>
          I’m a producer
        </Typography>
        <Typography component="p" className={classes.subtext}>
          Make sure you’re signing in with your producer account
        </Typography>
      </Grid>
    </Grid>
  </>
);

Forbidden.propTypes = {
  classes: PropTypes.shape(),
};

Forbidden.defaultProps = {
  classes: {},
};

export default Forbidden;
