import { Typography } from "~~/components/ds/Typography";

import { Link } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const NotFoundSig = {
  propTypes: {
    classes: PropTypes.shape(),
    rootUrl: PropTypes.string,
  },
};

const NotFound = ({ classes = {}, rootUrl = "/" }) => (
  <>
    <Typography component="h1" className={classes.pageHeader}>
      This page is a no-show
    </Typography>

    <Typography component="p" className={classes.subtext}>
      We checked everywhere, but it looks like this page does not exist
    </Typography>

    <Link href={rootUrl} className={classes.cta} underline="none">
      Go Home
    </Link>
  </>
);

NotFound.propTypes = NotFoundSig.propTypes;

export default NotFound;
