import PropTypes from "prop-types";
import React from "react";

const HeadphonesIcon = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="22"
    fill="none"
    viewBox="0 0 18 22"
  >
    <path
      fill={fill}
      d="M9 0a9 9 0 00-9 9v7c0 1.66 1.34 3 3 3h3v-8H2V9c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h4v1H9v2h6c1.66 0 3-1.34 3-3V9a9 9 0 00-9-9z"
    />
  </svg>
);

HeadphonesIcon.propTypes = {
  fill: PropTypes.string,
};

HeadphonesIcon.defaultProps = {
  fill: "white",
};

export default HeadphonesIcon;
