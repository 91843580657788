import PropTypes from "prop-types";
import React from "react";

const WelcomeArches = ({ stroke, className = "" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="838"
    height="837"
    fill="none"
    viewBox="0 0 838 837"
    className={className}
  >
    <path
      stroke={stroke}
      strokeOpacity="0.04"
      d="M1 419C1 188.145 188.145 1 419 1v0c230.855 0 418 187.145 418 418v1621H1V419z"
    />
    <path
      stroke={stroke}
      strokeOpacity="0.04"
      d="M1 580c0-164.029 132.971-297 297-297v0c164.029 0 297 132.971 297 297v854H1V580z"
    />
    <path
      stroke={stroke}
      strokeOpacity="0.04"
      d="M1 736c0-96.098 77.903-174 174-174v0c96.098 0 174 77.902 174 174v621H1V736z"
    />
  </svg>
);

WelcomeArches.propTypes = {
  stroke: PropTypes.string,
  className: PropTypes.string,
};

WelcomeArches.defaultProps = {
  stroke: "white",
  className: "",
};

export default WelcomeArches;
