/**
 * @fileoverview Method for managing the service. Sits at a higher level than
 * other services and aggregates methods from them.
 */
import { disconnectChatClient, initChatClient } from "~~/services/chatService";
import {
  subscribe as subscribeMonitoring,
  unsubscribe as unsubscribeMonitoring,
} from "~~/services/monitoringService";
import {
  subscribe as subscribePollConsumer,
  unsubscribe as unsubscribePollConsumer,
} from "~~/services/pollChannelService";
import {
  subscribeStageQuestionsConsumer,
  unsubscribe as unsubscribeStageQuestionsConsumer,
} from "~~/services/stageQuestionService";
import {
  confirmStartRecording,
  confirmStartRecordingContainer,
  subscribe as subscribeStageStatusConsumer,
  subscribeRecorder as subscribeStageStatusRecorder,
  unsubscribe as unsubscribeStageStatusConsumer,
} from "~~/services/stageService";
import {
  subscribe as subscribeSnapshotConsumer,
  unsubscribe as unsubscribeSnapshotConsumer,
} from "~~/services/stageSnapshotService";
import {
  subscribe as subscribeEventManagementConsumer,
  unsubscribe as unsubscribeEventManagementConsumer,
  subscribeRecorder as subscribeEventManagementRecorder,
} from "~~/services/eventManagementService";
import {
  initPrimaryClient,
  isUserStreaming,
  removeAllLocalStreams,
} from "~~/services/videoService";

/**
 * Connects to all required action cable consumers
 * N.B. "Required" here implies that the stage is not mounted.
 * These subscriptions here are ones that are required even if
 * the user is not actively in an event / stage
 *
 * @param {Number} stageId The stage ID to connect to
 */
export async function connectRequiredActionCable(
  stageId,
  signedStageId,
  eventId
) {
  return subscribeStageStatusConsumer(stageId, signedStageId, eventId);
}

/**
 * Connects to the deferred action cable consumers
 * N.B. "Deferred" here implies that the stage is mounted
 * and ready to go. This is meant so that we only need to connect
 * to the specific action cable subscriptions that we need
 * to display the event as it is ongoing.
 *
 * @param {Number} stageId The stage ID to connect to
 */
export async function connectDeferredActionCable(
  stageId,
  signedStageId,
  eventId
) {
  // NB: be careful modifying the order of these subscriptions as it may have unintended
  // side effects and race conditions, e.g. channel X is subscribed before channel Y but
  // it tries to broadcast a message to channel Y and the message fails to arrive
  subscribeEventManagementConsumer(stageId, signedStageId, eventId, false);
  subscribeSnapshotConsumer(stageId, signedStageId, eventId);
  subscribeStageQuestionsConsumer(stageId, signedStageId, eventId);
  subscribePollConsumer(stageId, signedStageId, eventId);
}

export async function connectRecorderDeferredActionCable(
  stageId,
  signedStageId,
  eventId,
  ec2HostName,
  authToken
) {
  Promise.all([
    subscribeStageStatusRecorder(stageId, signedStageId, eventId, ec2HostName),
    subscribeSnapshotConsumer(stageId, signedStageId, eventId),
    subscribeStageQuestionsConsumer(stageId, signedStageId, eventId),
    subscribePollConsumer(stageId, signedStageId, eventId),
    subscribeEventManagementRecorder(stageId, signedStageId, eventId, false),
    subscribeMonitoring(authToken),
    initPrimaryClient(), // Moved this here because we need to wait for the page to load
  ]).then(() => {
    confirmStartRecording(ec2HostName);
  });
}

export async function connectStreamerRendererDeferredActionCable(
  stageId,
  signedStageId,
  eventId,
  authToken
) {
  subscribeStageStatusConsumer(stageId, signedStageId, eventId);
  subscribeSnapshotConsumer(stageId, signedStageId, eventId);
  subscribeStageQuestionsConsumer(stageId, signedStageId, eventId);
  subscribePollConsumer(stageId, signedStageId, eventId);
  subscribeMonitoring(authToken);
}

export async function connectStreamerGrabberDeferredActionCable(
  stageId,
  signedStageId,
  eventId,
  authToken,
  shouldRecordStream
) {
  if (shouldRecordStream) {
    Promise.all([
      subscribeMonitoring(authToken),
      subscribeStageStatusRecorder(stageId, signedStageId, eventId),
    ]).then(() => {
      // In reality we need to wait for the data to flow, but this may be close enough
      confirmStartRecordingContainer();
    });
  } else {
    subscribeMonitoring(authToken);
  }
}

/**
 * Disconnects from all action cable consumers
 */
export function unsubscribeActionCable() {
  unsubscribeEventManagementConsumer();
  unsubscribeSnapshotConsumer();
  unsubscribeStageStatusConsumer();
  unsubscribeStageQuestionsConsumer();
  unsubscribePollConsumer();
  unsubscribeMonitoring();
}

export function connectAgora(
  isHost = false,
  clientRoleOptionsLevel = AgoraRTC.AudienceLatencyLevelType
    .AUDIENCE_LEVEL_ULTRA_LOW_LATENCY
) {
  initPrimaryClient(isHost, clientRoleOptionsLevel);
}

export function disconnectAgora() {
  removeAllLocalStreams(false, /* removeAll = */ true);
}

export function connectStreamChat() {
  initChatClient();
}

export function disconnectStreamChat() {
  disconnectChatClient();
}

window.onbeforeunload = () => {
  if (isUserStreaming()) {
    return "Are you sure you want to leave? Your video still be stopped.";
  }

  return null;
};
