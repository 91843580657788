import PropTypes from "prop-types";
import React from "react";

const WelcomeLogoIcon = ({ fill = "#e9e9e9" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="24"
    fill="none"
    viewBox="0 0 96 24"
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M0 10.4C0 4.656 4.656 0 10.4 0c5.744 0 10.4 4.656 10.4 10.4V24H0V10.4zm1.6 7.2v4.8H8v-4.8a3.2 3.2 0 00-6.4 0zm8 4.8v-4.8a4.8 4.8 0 00-8-3.578V14a6 6 0 1112 0v8.4h-4zm9.6 0h-4V14A7.6 7.6 0 001.676 9.239 8.802 8.802 0 0119.2 10.4v12z"
      clipRule="evenodd"
    />
    <path fill={fill} d="M51.269 17.18h1.797V6.8H51.27v10.38z" />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M47.847 15.475c-.431.397-1.16.574-1.976.574-1.204 0-2.14-.383-2.496-1.176a2.157 2.157 0 01-.208-.794h6.983c0-.868-.12-1.545-.386-2.191-.61-1.338-2.006-2.147-4.012-2.147-1.976 0-3.447.75-4.056 2.117-.222.5-.341 1.044-.341 1.72 0 .648.104 1.206.341 1.692.61 1.367 2.11 2.131 4.16 2.131 1.352 0 2.555-.308 3.358-1.029.346-.311.596-.674.75-1.103h-1.922a1.424 1.424 0 01-.195.206zm-2.095-4.367c1.144 0 2.035.353 2.407 1.162.074.176.134.338.163.544h-5.125c.03-.177.089-.338.163-.5.371-.823 1.218-1.206 2.392-1.206z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      d="M61.895 16.343c-.787.69-1.931 1.058-3.328 1.058-1.902 0-3.373-.72-3.996-2.102-.223-.5-.342-1.073-.342-1.735 0-.647.119-1.235.342-1.72.623-1.353 2.065-2.103 3.996-2.103 1.397 0 2.526.353 3.313 1.073.58.5.891 1.162.936 1.956H60.9a1.578 1.578 0 00-.49-1.015c-.417-.367-1.085-.53-1.828-.53-1.085 0-1.901.413-2.258 1.162a3.039 3.039 0 00-.208 1.177c0 .47.074.838.208 1.161.357.75 1.173 1.162 2.258 1.162.743 0 1.426-.162 1.827-.544.297-.25.476-.603.49-1.015h1.947c-.06.81-.371 1.486-.951 2.015z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M68.27 17.387c2.08 0 3.507-.794 4.116-2.132.223-.5.342-1.059.342-1.691 0-.618-.12-1.177-.342-1.676-.61-1.338-2.035-2.147-4.115-2.147-2.066 0-3.492.809-4.115 2.176-.223.485-.327 1.044-.327 1.647 0 .617.104 1.161.327 1.661.623 1.353 2.05 2.162 4.115 2.162zm0-1.47c-1.129 0-1.99-.427-2.362-1.221a2.912 2.912 0 01-.208-1.132c0-.427.075-.809.208-1.118.372-.794 1.233-1.22 2.363-1.22 1.129 0 2.005.412 2.376 1.22.134.31.194.691.194 1.118 0 .44-.06.809-.194 1.132-.37.794-1.247 1.22-2.376 1.22zM91.721 16.049c.817 0 1.545-.177 1.976-.574.072-.062.138-.132.196-.206h1.92c-.153.43-.403.792-.75 1.103-.801.72-2.005 1.03-3.357 1.03-2.05 0-3.55-.765-4.16-2.133-.237-.485-.341-1.043-.341-1.69 0-.677.119-1.22.342-1.72.609-1.368 2.08-2.118 4.055-2.118 2.006 0 3.403.809 4.011 2.147.268.646.387 1.323.387 2.19h-6.983c.03.31.09.56.209.795.356.793 1.292 1.176 2.495 1.176zm2.288-3.78c-.371-.808-1.263-1.16-2.407-1.16-1.173 0-2.02.382-2.391 1.205a1.965 1.965 0 00-.164.5h5.126a2.117 2.117 0 00-.164-.544z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      d="M77.374 9.756a3.514 3.514 0 00-3.513 3.515v3.915h1.836v-4.304c0-.927.75-1.648 1.677-1.648.925 0 1.676.721 1.676 1.648v4.304h1.836v-4.304c0-.927.75-1.648 1.677-1.648.926 0 1.676.721 1.676 1.648v4.304h1.837V13.27a3.514 3.514 0 00-6.108-2.37 3.503 3.503 0 00-2.594-1.145zM37.257 15.125l1.895-5.172h1.925l-2.91 7.215h-1.821l-1.807-5.055-1.808 5.055H30.91L28 9.953h1.925l1.895 5.187 1.808-5.187h1.807l1.822 5.172z"
    />
  </svg>
);

WelcomeLogoIcon.propTypes = {
  fill: PropTypes.string,
};

WelcomeLogoIcon.defaultProps = {
  fill: "#e9e9e9",
};

export default WelcomeLogoIcon;
