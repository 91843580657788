import cable from "~~/utils/cable";
import axios from "~~/utils/authenticatedAxios";
import store from "~~/redux/store";
import { POLL_STATUS } from "~~/constants/pollStatus";
import {
  archivePollFromChannel,
  createPollFromChannel,
  deletePollFromChannel,
  setMostRecentlyOpenedPoll,
  setPolls,
  updatePollFromChannel,
  voteForPoll,
} from "~~/redux/actions/polls";
import { showPollNotification } from "~~/services/panelNotificationService";
import { isProducerViewMode } from "~~/services/viewModeService";

// Constants
const CHANNEL_NAME = "PollChannel";
let subscription = null;

const POLL_CHANNEL_MESSAGE_TYPE = {
  ARCHIVE: "archive",
  CREATE: "create",
  DESTROY: "destroy",
  INITIAL: "initial",
  UPDATE: "update",
  VOTE: "vote",
};

export async function subscribe(stageId, signedStageId, eventId) {
  return new Promise((resolve, reject) => {
    subscription = cable.subscriptions.create(
      {
        channel: CHANNEL_NAME,
        stage_id: stageId,
        signed_stage_id: signedStageId,
        event_id: eventId,
      },
      {
        received: ({ data, message_type: messageType }) => {
          const { ARCHIVE, CREATE, DESTROY, INITIAL, UPDATE, VOTE } =
            POLL_CHANNEL_MESSAGE_TYPE;
          const isProducerView = isProducerViewMode();
          switch (messageType) {
            case ARCHIVE:
              store.dispatch(archivePollFromChannel(data.hashid));
              break;
            case CREATE:
              if (isProducerView) {
                store.dispatch(createPollFromChannel(data));
              }
              break;
            case DESTROY:
              store.dispatch(deletePollFromChannel(data.hashid));
              break;
            case INITIAL:
              if (isProducerView) {
                store.dispatch(setPolls(data));
                break;
              }
              // eslint-disable-next-line no-case-declarations
              const pollsToAdd = data.filter(
                (poll) => poll.status !== POLL_STATUS.CREATED
              );
              store.dispatch(setPolls(pollsToAdd));
              break;
            case UPDATE:
              if (!isProducerView) {
                const existingPollData =
                  store.getState().Polls.byHashid[data.hashid];
                const wasJustOpened =
                  existingPollData?.status !== POLL_STATUS.OPENED &&
                  data.status === POLL_STATUS.OPENED;
                const onStagePollHashid =
                  store.getState().Video.stageSnapshot.pollHashid;

                if (wasJustOpened && onStagePollHashid !== data.hashid) {
                  store.dispatch(setMostRecentlyOpenedPoll(data.hashid));
                  showPollNotification();
                }
              }

              if (data.status === POLL_STATUS.ARCHIVED) {
                store.dispatch(archivePollFromChannel(data.hashid));
              } else {
                store.dispatch(updatePollFromChannel(data));
              }
              break;
            case VOTE:
              store.dispatch(voteForPoll(data));
              break;
            default:
              break;
          }
        },
        initialized: () => {},
        connected: () => {
          // Mirrors case: INITIAL above
          const isProducerView = isProducerViewMode();
          const eventId = store.getState().Event.id;

          axios
            .get(`/events/${eventId}/stages/${stageId}/polls`)
            .then((response) => {
              const data = response.data.data;

              if (isProducerView) {
                store.dispatch(setPolls(data));
                return;
              }
              const pollsToAdd = data.filter(
                (poll) => poll.status !== POLL_STATUS.CREATED
              );
              store.dispatch(setPolls(pollsToAdd));
            });
          resolve();
        },
        rejected: reject,
      }
    );
  });
}

export async function unsubscribe() {
  if (subscription) {
    subscription.unsubscribe();
    subscription = null;
  }
}
