import { Box, Link, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import App from "~~/components/common/App";
import WelcomeArches from "~~/components/common/icons/WelcomeArches";
import WelcomeLogoIcon from "~~/components/common/icons/WelcomeLogoIcon";
import Forbidden from "~~/components/errors/Show/Forbidden";
import Generic from "~~/components/errors/Show/Generic";
import LostPasswordless from "~~/components/errors/Show/LostPasswordless";
import NotFound from "~~/components/errors/Show/NotFound";
import { logoutAndReload } from "~~/services/userService";

import { Button } from "~~/components/ds/button";

export const useStyles = makeStyles((theme) => ({
  layout: {
    height: "100vh",
    width: "100vw",
    background: "#0F0F10",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  centerBox: {
    width: "95%",
    maxWidth: theme.spacing(65),
    backgroundColor: "#18191B",
    padding: theme.spacing(5),
    textAlign: "left",
    boxShadow: "0px 48px 120px rgba(0, 0, 0, 0.48)",
    position: "relative",
    zIndex: 1,
  },
  pageHeader: {
    fontSize: "1.5rem",
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    color: "white",
  },
  alert: {
    fontSize: "0.9rem",
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    color: "#18191B",
    background: "#ebd583",
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  subtext: {
    color: "#9F9FA2",
    fontWeight: 400,
    fontSize: "0.875rem",
    marginTop: theme.spacing(1),
  },
  cta: {
    display: "block",
    width: "100%",
    color: "white",
    padding: "0.5rem 1rem",
    borderRadius: "100px",
    fontWeight: 600,
    marginTop: theme.spacing(5),
    textAlign: "center",
    backgroundColor: "#2B2C2F",
    textDecoration: "none",
  },
  arches: {
    position: "fixed",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    maxHeight: "85vh",
  },
  logo: {
    position: "fixed",
    top: theme.spacing(3),
    left: theme.spacing(3),
  },
  link: {
    "& a": {
      color: "#e6e5e8",
    },
  },
}));

const statusComponentMappings = Object.freeze({
  403: <Forbidden />,
  404: <NotFound />,
});

const errorComponentMappings = Object.freeze({
  lostPasswordless: <LostPasswordless />,
  generic: <Generic />,
});

const ErrorSig = {
  propTypes: {
    params: PropTypes.shape(),
    data: PropTypes.shape(),
    currentUser: PropTypes.shape(),
  },
};

const Error = ({ currentUser, data = {}, params = {} }) => {
  const isLoggedin = Boolean(currentUser);
  const rootUrl = data.root_url || "/";
  const classes = useStyles();

  let RenderComponent;
  if (params?.code) {
    RenderComponent = statusComponentMappings[params.code];
  }

  if (data?.errorPage) {
    RenderComponent = errorComponentMappings[data?.errorPage];
  }

  if (!RenderComponent) {
    RenderComponent = errorComponentMappings.generic;
  }

  return (
    <App>
      <Box className={classes.layout}>
        <Link href={rootUrl} className={classes.logo} m="2">
          <WelcomeLogoIcon />
        </Link>
        <Box className={classes.centerBox}>
          {React.cloneElement(RenderComponent, {
            classes,
            rootUrl,
            isLoggedin,
          })}
        </Box>
        {isLoggedin && (
          <Button
            type="button"
            onClick={logoutAndReload}
            style={{
              position: "absolute",
              right: 24,
              top: 24,
            }}
          >
            Log out
          </Button>
        )}
        <WelcomeArches className={classes.arches} />
      </Box>
    </App>
  );
};

Error.propTypes = ErrorSig.propTypes;

export default Error;
