/* eslint-disable import/prefer-default-export */
import STAGE_QUESTION_STATUS from "~~/constants/stageQuestionStatus";
import MESSAGE_TYPE from "~~/constants/stageQuestionUpdateType";
import * as stageQuestionVoteActions from "~~/redux/actions/stageQuestionVotes";
import * as actions from "~~/redux/actions/stageQuestions";
import {
  getQuestionsAskedByCurrentRegistration,
  getUnansweredQuestionsForStage,
} from "~~/redux/selectors/stageQuestions";
import store from "~~/redux/store";
import { showQnaNotification } from "~~/services/panelNotificationService";
import {
  denyAskQuestions,
  permitAskQuestions,
} from "~~/services/permissionsService";
import { fetchReg, fetchRegs } from "~~/services/registrationService";
import axios from "~~/utils/authenticatedAxios";
import cable from "~~/utils/cable";
import WLog from "~~/wlog";
import { getFeatureFlag } from "~~/utils/featureFlags";

// Constants
const CHANNEL = "StageQuestionsChannel";
const MAXIMUM_TOTAL_QUESTIONS_PER_STAGE = 100;
const MAXIMUM_TOTAL_QUESTIONS_PER_USER = 10;

let subscription = null;

export function updateCanUserAskQuestions() {
  if (
    getQuestionsAskedByCurrentRegistration(store.getState()).length >=
      MAXIMUM_TOTAL_QUESTIONS_PER_USER ||
    getUnansweredQuestionsForStage(store.getState()).length >=
      MAXIMUM_TOTAL_QUESTIONS_PER_STAGE
  ) {
    denyAskQuestions();
  } else {
    permitAskQuestions();
  }
}

export function _onNewStageQuestionsReceived(stageQuestions) {
  const prevStageQuestions = store.getState().StageQuestions;
  if (!stageQuestions || !prevStageQuestions) {
    return;
  }
  if (
    Object.keys(stageQuestions).length ===
    Object.keys(prevStageQuestions).length + 1
  ) {
    showQnaNotification();
  }

  // get registrations as well if any are missing
  const eventRegistrationIdsFromStageQuestions = Array.from(
    new Set(
      Object.keys(stageQuestions).map((stageQuestionId) => {
        return stageQuestions[stageQuestionId].eventRegistrationId;
      })
    )
  );
  fetchRegs(eventRegistrationIdsFromStageQuestions).catch(console.error);

  store.dispatch(actions.setStageQuestions(stageQuestions));
}

export function handleStageQuestionUpdates(stageQuestionData) {
  switch (stageQuestionData.updateType) {
    case MESSAGE_TYPE.SET:
      store.dispatch(
        stageQuestionVoteActions.setVotedOnStageQuestionIds(
          stageQuestionData.userVotedQuestions
        )
      );
      _onNewStageQuestionsReceived(stageQuestionData.questions);
      break;
    case MESSAGE_TYPE.NEW:
      store.dispatch(actions.addStageQuestion(stageQuestionData.stageQuestion));
      showQnaNotification();
      updateCanUserAskQuestions();
      break;
    case MESSAGE_TYPE.UPDATE:
      store.dispatch(
        actions.updateStageQuestion(stageQuestionData.stageQuestion)
      );
      if (
        stageQuestionData.stageQuestion.status ===
        STAGE_QUESTION_STATUS.ANSWERED
      ) {
        updateCanUserAskQuestions();
      }
      if (
        stageQuestionData.stageQuestion?.status ===
        STAGE_QUESTION_STATUS.REJECTED
      ) {
        const currentEventRegistrationId =
          store.getState().User.registration?.id;
        if (
          stageQuestionData.stageQuestion?.eventRegistrationId ===
          currentEventRegistrationId
        ) {
          window.flash_messages.flashAlert(
            "Your question was removed by a moderator."
          );
        }
      }
      break;
    case MESSAGE_TYPE.DELETE:
      store.dispatch(
        actions.deleteStageQuestion(stageQuestionData.stageQuestion)
      );
      updateCanUserAskQuestions();
      break;
    case MESSAGE_TYPE.UPVOTE:
      store.dispatch(
        actions.upvoteStageQuestion(stageQuestionData.stageQuestion)
      );
      break;
    case MESSAGE_TYPE.DOWNVOTE:
      store.dispatch(
        actions.downvoteStageQuestion(stageQuestionData.stageQuestion)
      );
      break;
    case MESSAGE_TYPE.ARCHIVE:
      store.dispatch(actions.archiveStageQuestions());
      updateCanUserAskQuestions();
      break;
    default:
  }
}

export async function archiveStageQuestions() {
  const stage = store.getState().Stage;
  await axios.post(
    `/events/${stage.eventId}/stages/${stage.id}/stage_question_archivals`
  );
}

export function setStageQuestions(stageQuestionsData) {
  _onNewStageQuestionsReceived(stageQuestionsData);
}

export async function fetchStageQuestion(stageQuestionId) {
  const stage = store.getState().Stage;
  const eventRegistrations = store.getState().Registrations;
  const { data } = await axios.get(
    `/events/${stage.eventId}/stages/${stage.id}/stage_questions/${stageQuestionId}`
  );
  // also check if registration for question is present, otherwise fetch it too
  if (!eventRegistrations[data[stageQuestionId].eventRegistrationId]) {
    await fetchReg(data[stageQuestionId].eventRegistrationId);
  }
  store.dispatch(actions.addStageQuestion(data[stageQuestionId]));
}

// Create a new stage question
export async function createStageQuestion(stageQuestion) {
  const stage = store.getState().Stage;
  const response = await axios.post(
    `/events/${stage.eventId}/stages/${stage.id}/stage_questions`,
    stageQuestion
  );
  store.dispatch(
    stageQuestionVoteActions.addVotedOnStageQuestionId(
      Object.keys(response.data)[0]
    )
  );
}

export async function deleteStageQuestion(stageQuestion) {
  const stage = store.getState().Stage;
  await axios.delete(
    `/events/${stage.eventId}/stages/${stage.id}/stage_questions/${stageQuestion.id}`
  );
}

export async function markStageQuestionAsAnswered(stageQuestion) {
  const stage = store.getState().Stage;
  await axios.patch(
    `/events/${stage.eventId}/stages/${stage.id}/stage_questions/${stageQuestion.id}`,
    { stageQuestion: { status: "answer" } }
  );
}

export async function markStageQuestionAsUnanswered(stageQuestion) {
  const stage = store.getState().Stage;
  await axios.patch(
    `/events/${stage.eventId}/stages/${stage.id}/stage_questions/${stageQuestion.id}`,
    { stageQuestion: { status: "ask" } }
  );
}

export async function approveQuestion(stageQuestion) {
  const stage = store.getState().Stage;

  // Optimistically update the stage question
  store.dispatch(actions.approveStageQuestion(stageQuestion.id));

  try {
    await axios.patch(
      `/events/${stage.eventId}/stages/${stage.id}/stage_questions/${stageQuestion.id}`,
      { stageQuestion: { status: "approve" } }
    );
  } catch (e) {
    store.dispatch(actions.unapproveStageQuestion(stageQuestion.id));
    throw e;
  }
}

export async function rejectQuestion(stageQuestion) {
  const stage = store.getState().Stage;

  // Optimistically reject stage question
  store.dispatch(actions.rejectStageQuestion(stageQuestion.id));

  try {
    await axios.patch(
      `/events/${stage.eventId}/stages/${stage.id}/stage_questions/${stageQuestion.id}`,
      { stageQuestion: { status: "reject" } }
    );
  } catch (e) {
    store.dispatch(actions.unrejectStageQuestion(stageQuestion.id));
    throw e;
  }
}

export async function voteOnStageQuestion(stageQuestion) {
  const stage = store.getState().Stage;
  await axios.post(
    `/events/${stage.eventId}/stages/${stage.id}/stage_questions/${stageQuestion.id}/votes`
  );
  store.dispatch(
    stageQuestionVoteActions.addVotedOnStageQuestionId(stageQuestion.id)
  );
}

export async function unvoteOnStageQuestion(stageQuestion) {
  const stage = store.getState().Stage;
  // ID is zero here because it doesn't matter what the vote ID is, we look it up via the join of question X registration
  await axios.delete(
    `/events/${stage.eventId}/stages/${stage.id}/stage_questions/${stageQuestion.id}/votes/0`
  );
  store.dispatch(
    stageQuestionVoteActions.removeVotedOnStageQuestionId(stageQuestion.id)
  );
}

export async function addStageQuestionReply(stageQuestion, reply) {
  const stage = store.getState().Stage;

  await axios.post(
    `/events/${stage.eventId}/stages/${stage.id}/stage_questions/${stageQuestion.id}/replies`,
    {
      stageQuestionReply: {
        responseText: reply,
        stageQuestionId: stageQuestion.id,
      },
    }
  );
}

export async function removeStageQuestionAnswer(
  stageQuestion,
  stageQuestionReply
) {
  const stage = store.getState().Stage;

  await axios.delete(
    `/events/${stage.eventId}/stages/${stage.id}/stage_questions/${stageQuestion.id}/replies/${stageQuestionReply.id}`
  );
}

async function fetchStageQuestions(eventId, stageId) {
  const { data } = await axios.get(
    `/events/${eventId}/stages/${stageId}/stage_questions?include_user_voted_questions=true`
  );
  data.updateType = MESSAGE_TYPE.SET;
  handleStageQuestionUpdates(data);
}

// subscribe to stage status changes
export async function subscribeStageQuestionsConsumer(
  stageId,
  signedStageId,
  eventId
) {
  return new Promise((resolve, reject) => {
    subscription = cable.subscriptions.create(
      {
        channel: CHANNEL,
        stage_id: stageId,
        signed_stage_id: signedStageId,
        event_id: eventId,
      },
      {
        received: (data) => {
          handleStageQuestionUpdates(data);
        },
        initialized: () => {},
        connected: async () => {
          if (getFeatureFlag("releaseInitialDataOverRestNotWs")) {
            try {
              await fetchStageQuestions(store.getState().Event.id, stageId);
            } catch (error) {
              console.error(error);
            }
          }
          resolve();
        },
        disconnected: () => {
          WLog.log("warn", "cable.stagequestion", `Disconnected: ${CHANNEL}`);
        },
        rejected: () => {
          WLog.log("warn", "cable.stagequestion", `Rejected: ${CHANNEL}`);
          reject();
        },
      }
    );
  });
}

export async function unsubscribe() {
  if (!subscription) {
    return;
  }
  subscription.unsubscribe();
}
