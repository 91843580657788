import CustomCable from "~~/utils/CustomCable";
import WLog from "~~/wlog";

// Constants
const CHANNEL = "MonitoringChannel";

const MSG_TYPE = {
  PLACEHOLDER: "placeholder",
};
let subscription = null;

export function heartbeat(key, value) {
  subscription?.perform("heartbeat", { key, value });
}

export function heartbeatRecording(dataObj) {
  subscription?.perform("heartbeat_recording", dataObj);
}

export function heartbeatStreaming(dataObj) {
  subscription?.perform("heartbeat_streaming", dataObj);
}

export async function subscribe(authToken) {
  const cable = new CustomCable(
    {
      channel: CHANNEL,
      auth_token: authToken,
    },
    {
      received: (data) => {
        switch (data.messageType) {
          case MSG_TYPE.PLACEHOLDER:
            WLog.log("warn", "cable.monitoring", "Received unexpected message");
            break;
          default:
            break;
        }
      },
    }
  );
  await cable.subscribe();
  subscription = cable;
}

export async function unsubscribe() {
  if (!subscription) {
    return;
  }
  subscription.unsubscribe();
}
