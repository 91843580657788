import { Fade } from "@material-ui/core";
import React from "react";
import WelcomeLoading from "~~/images/loading-144.gif";

export default function FullScreenLoader() {
  return (
    <Fade in>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          position: "fixed",

          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",

          background: "#F7F7F7",
          color: "black",
        }}
        aria-label="Page loading indicator"
      >
        <div
          style={{
            marginBottom: 16,
            paddingBottom: 24,
            position: "relative",
          }}
        >
          <img
            src={WelcomeLoading}
            style={{ width: 80, height: 80 }}
            alt="Welcome Logo"
          />
        </div>
      </div>
    </Fade>
  );
}
