import { createSelector } from "reselect";

const getCurrentRegistration = (state) => state.User.registration;
const getStageQuestions = (state) => Object.values(state.StageQuestions);

export const getQuestionsAskedByCurrentRegistration = createSelector(
  [getStageQuestions, getCurrentRegistration],
  (stageQuestions, currentRegistration) => {
    return stageQuestions.filter(
      (stageQuestion) =>
        currentRegistration.id === stageQuestion.eventRegistrationId &&
        stageQuestion.status !== "answered"
    );
  }
);

export const getUnansweredQuestionsForStage = createSelector(
  getStageQuestions,
  (stageQuestions) => {
    return stageQuestions.filter(
      (stageQuestion) => stageQuestion.status !== "answered"
    );
  }
);
